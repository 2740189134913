import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage/MainPage';
import Product from './pages/Product/Product';
import "react-round-carousel/src/index.css";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage></MainPage>} />
          <Route path="/product/:id" element={<Product></Product>}></Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
